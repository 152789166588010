import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classes from "./UpcomingReservations.module.scss";
import { upcomingReservationsSelector } from "../../../../../redux/selectors/reservationsData";
import { Divider } from "@mui/material";
import { shortMonthsNames } from "../../../../../utils/constants/periodLists";
import {RightChevron} from "../../../../../assets/icons/RightChevron";

export const UpcomingReservations = () => {
  const upcomingReservations: any = useSelector(upcomingReservationsSelector);

  return (
    <div className={classes.upcomingReservContainer}>
      <div className={classes.upcomingReservHeader}>
        <h4>
          Upcoming <br /> Reservations
        </h4>
        <Link className={classes.link} to="/calendar/reservations">
          See all
        </Link>
      </div>
      <div className={classes.reservations}>
        {upcomingReservations &&
          Object.keys(upcomingReservations).map((yearKey: string) => {
            const yearData = upcomingReservations[yearKey];

            return Object.keys(yearData).map((monthKey: string) => {
              const monthData = yearData[monthKey];

              return Object.keys(monthData).map((dateKey: string) => {
                const {
                  checkin_at,
                  nights_count,
                  netincome,
                  guest_count,
                } = monthData[dateKey];

                const monthInd = new Date(checkin_at).getUTCMonth();
                const day = new Date(checkin_at).getUTCDate();
                const isPaid: boolean = Boolean(netincome);

                return (
                  <div
                    className={classes.reservation}
                    key={`reservation_${checkin_at}`}
                  >
                    <div className={classes.reservationContent}>
                      <div className={classes.date}>
                        <div className={classes.date__innerWrapper}>
                          <p>{day}</p>
                          <p>{shortMonthsNames[monthInd]}</p>
                        </div>
                      </div>
                      <Divider
                        className={isPaid ? classes.paid : classes.owner}
                        flexItem
                        orientation="vertical"
                      />
                      <div className={classes.ownerBlocks}>
                        <p className={isPaid ? classes.green : classes.blue}>
                          {netincome ? "Paid Reservation" : "Owner Block"}
                        </p>
                        <p>
                          {nights_count} Nights
                        </p>
                        {isPaid && <p>
                          {guest_count} Guests
                        </p>}
                      </div>
                    </div>
                    <Link className={classes.link} to="/calendar/reservations">
                      <RightChevron />
                    </Link>
                  </div>
                );
              });
            });
          })}
      </div>
    </div>
  );
};
